import {
  format,
  isSameYear,
  fromUnixTime,
  formatDistanceToNow,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';

/**
 * Formats a Unix timestamp into a human-readable time format.
 * @param {number} time - Unix timestamp.
 * @param {string} [dateFormat='h:mm a'] - Desired format of the time.
 * @returns {string} Formatted time string.
 */
export const messageStamp = (time, dateFormat = 'h:mm a') => {
  const unixTime = fromUnixTime(time);
  return format(unixTime, dateFormat, { locale: ptBR });
};

/**
 * Provides a formatted timestamp, adjusting the format based on the current year.
 * @param {number} time - Unix timestamp.
 * @param {string} [dateFormat='MMM d, yyyy'] - Desired date format.
 * @returns {string} Formatted date string.
 */
export const messageTimestamp = (time, dateFormat = 'MMM d, yyyy') => {
  const messageTime = fromUnixTime(time);
  const now = new Date();
  const messageDate = format(messageTime, dateFormat, { locale: ptBR });
  if (!isSameYear(messageTime, now)) {
    return format(messageTime, "LLL d 'de' y', 'HH:mm", { locale: ptBR });
  }
  return messageDate;
};

/**
 * Converts a Unix timestamp to a relative time string (e.g., 3 hours ago).
 * @param {number} time - Unix timestamp.
 * @returns {string} Relative time string.
 */
export const dynamicTime = time => {
  const unixTime = fromUnixTime(time);
  return formatDistanceToNow(unixTime, { addSuffix: true, locale: ptBR });
};

/**
 * Formats a Unix timestamp into a specified date format.
 * @param {number} time - Unix timestamp.
 * @param {string} [dateFormat='MMM d, yyyy'] - Desired date format.
 * @returns {string} Formatted date string.
 */
export const dateFormat = time => {
  const unixTime = fromUnixTime(time);
  return format(unixTime, dateFormat, { locale: ptBR });
};

/**
 * Converts a detailed time description into a shorter format, optionally appending 'ago'.
 * @param {string} time - Detailed time description (e.g., 'a minute ago').
 * @param {boolean} [withAgo=false] - Whether to append 'ago' to the result.
 * @returns {string} Shortened time description.
 */
export const shortTimestamp = (time, withAgo = false) => {
  // This function takes a time string and converts it to a short time string
  // with the following format: 1m, 1h, 1d, 1mo, 1y
  // The function also takes an optional boolean parameter withAgo
  // which will add the word "ago" to the end of the time string
  const suffix = withAgo ? ' ago' : '';
  const timeMappings = {
    'menos de um minuto atás': 'agora',
    'um minuto atrás': `1min${suffix}`,
    'uma hora atrás': `1hora${suffix}`,
    'um dia atrás': `1dia${suffix}`,
    'um mês atrás': `1mes${suffix}`,
    'um ano atrás': `1a${suffix}`,
  };
  // Check if the time string is one of the specific cases
  if (timeMappings[time]) {
    return timeMappings[time];
  }
  const convertToShortTime = time
    .replace(/about|over|almost|/g, '')
    .replace(' minuto atrás', `min${suffix}`)
    .replace(' minutos atrás', `mins${suffix}`)
    .replace(' hora atrás', `hora${suffix}`)
    .replace(' horas atrás', `horas${suffix}`)
    .replace(' dia atrás', `dia${suffix}`)
    .replace(' dias atrás', `dias${suffix}`)
    .replace(' mês atrás', `mes${suffix}`)
    .replace(' meses atrás', `meses${suffix}`)
    .replace(' ano atrás', `ano${suffix}`)
    .replace(' anos atrás', `anos${suffix}`);
  return convertToShortTime;
};
